import ChamberList from "../pages/chambers/ChamberList";
import SingleChamber from "../pages/chambers/SingleChamber";
import Home from "../pages/home/Home";
import LoginPage from "../pages/auth/LoginPage";
import LawyerList from "../pages/lawyers/LawyerList";
import LawyerYears from "../pages/lawyers/LawyerYears";
import SingleLawyer from "../pages/lawyers/SingleLawyer";

export const publicRoutes = [
    { path: "/", element: <Home /> },
    { path: "/lawyers", element: <LawyerList /> },
    { path: "/lawyers/:id", element: <SingleLawyer /> },
    { path: "/lawyers/year/:id", element: <LawyerYears /> },
    { path: "/chambers", element: <ChamberList /> },
    { path: "/chambers/:id", element: <SingleChamber /> },
];
