export const ALL_LAWYERS = "/locator/lawyer";
export const FILTER_LAWYERS = "/locator/lawyer/filter";
export const SINGLE_LAWYER = "/locator/lawyer";
export const CONTACT_LAWYER = "/locator/lawyer/contact";
export const YEAR_LAWYER = "/locator/lawyer/year";

export const ALL_CHAMBERS = "/locator/chamber";
export const FILTER_CHAMBERS = "/locator/chamber/filter";
export const SINGLE_CHAMBER = "/locator/chamber/single";

export const LOGIN_API = "/auth/login";
export const GET_PROFILE = "/auth/profile";
