import { Suspense, useEffect } from "react";
import FallbackLoading from "./common/loader/FallbackLoading";
import { Route, Routes } from "react-router-dom";
import { publicRoutes } from "./routes/public.routes";
import TopNavbar from "./common/shared/TopNavbar";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

import LoginPage from "./pages/auth/LoginPage";

function App() {
    const location = useLocation();

    // window.addEventListener(
    //     "message",
    //     (event) => {
    //         if (event.origin !== "https://svbredte545fx.ghanabar.org/") return;

    //         const receivedData = JSON.parse(event.data);
    //         console.log(receivedData);
    //     },
    //     false
    // );

    useEffect(() => {
        const message = Cookies.get("dataCookie");

        if (message) {
            console.log("<-------message------>", message);

            localStorage.setItem("profile", message);
        }
        // const handleMessage = (event) => {
        //   console.log("<--------event-------->", event);
        //   if (event.origin !== "https://svbredte545fx.ghanabar.org") return;

        //   const { key, value } = event.data;
        //   localStorage.setItem(key, value);
        // };

        // window.addEventListener("message", handleMessage);
        // return () => window.removeEventListener("message", handleMessage);
    }, []);

    return (
        <div className="bg-[#EFEFF1]">
            {/* Conditionally render TopNavbar if not on the login page */}
            {location.pathname !== "/login" && <TopNavbar />}

            <Suspense fallback={<FallbackLoading />}>
                <Routes>
                    {publicRoutes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.element} />
                    ))}
                    <Route path="/login" element={<LoginPage />} />
                </Routes>
            </Suspense>
        </div>
    );
}

export default App;
